import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import Diff from "../../components/Diff";
import PrismExample from "../../components/PrismExample";
import vcImg from "../../images/vscode-version-control.png";
import { BlogImage, Description } from "../../components/BlogComponents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Kyle Matthews, author of the Gatsbyjs project, tweeted asking whether anyone had made a React component making use of the `}<inlineCode parentName="p">{`diff`}</inlineCode>{` package from NPM. Since I had made one for work a few weeks back, and since this website is largely powered by the framework that he put out into the world for free, I thought I'd rewrite a version of the diff component and share it.
This feature is inspired by various string comparison tools, commonly used to help make sense of Git versioning. GitHub, VSCode, and some other tools have some realy good examples of this.`}</p>
    <BlogImage src={vcImg} alt="example diff of a VSCode editor" mdxType="BlogImage">
  Example provided int the VSCode docs
    </BlogImage>
    <p>{`My use case never didn't require the "line-by-line" style comparison for git, but was more focused on an expectation of finding small differences between different people's inputs. I went with an approach that could be customized to either show a diff by letters or by words.`}</p>
    <p>{`Here's the example I built!`}</p>
    <iframe src="https://codesandbox.io/embed/react-jsdiff-l4ydo?fontsize=14&hidenavigation=1&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="React JSDiff" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
    <h2 id="prismjs">Use with Prism JS</h2>
    <p>{`It's also possible to use this for an inline text diff for code samples using Prism js!`}</p>
    <PrismExample mdxType="PrismExample" />
    <p>{`This gets a bit tricky, because Prism JS isn't aware of the React lifecycle, and replaces every element inside the target `}<inlineCode parentName="p">{`code`}</inlineCode>{` tag with custom spans. That can cause the code to break pretty easily. Fortunately, you can solve this by using multiple `}<inlineCode parentName="p">{`code`}</inlineCode>{` tags, on either side of your Diff-ed text.`}</p>
    <p>{`Also, you will need to modify the React Diff component to run on any text that is included in the string that you want highlighting on. That will end up looking like this:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// PrismDiff.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`React`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"react"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="span" {...{
            "className": "token keyword module"
          }}>{`as`}</span>{` diff`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"diff"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`PropTypes`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"prop-types"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Prism`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"prismjs"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` styles `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  added`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    color`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"green"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    backgroundColor`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"#b5efdb"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    textShadow`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"none"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  removed`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    color`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"red"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    backgroundColor`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"#fec4c0"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    textShadow`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"none"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PrismDiff`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` string1 `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` string2 `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` mode `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"characters"`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` groups `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`mode `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"characters"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` groups `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` diff`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`diffChars`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`string1`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` string2`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`mode `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`===`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"words"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` groups `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` diff`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`diffWords`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`string1`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` string2`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` mappedNodes `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` groups`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`map`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`group`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` added`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` removed `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` group`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` nodeStyles`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`added`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` nodeStyles `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styles`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`added`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`removed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` nodeStyles `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styles`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`removed`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Using dangerouslySetInnerHTML with the Node rendering API`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Note: is dangerous`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`span
        style`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`nodeStyles`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        dangerouslySetInnerHTML`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          __html`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Prism`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`highlight`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
            value`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Prism`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`languages`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`javascript`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
            `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"javascript"`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`span`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`mappedNodes`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`span`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PrismDiff`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`propTypes`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  string1`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PropTypes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`string`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  string2`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PropTypes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`string`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  mode`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PropTypes`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`oneOf`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"characters"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"words"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PrismDiff`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// PrismExample.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useLayoutEffect `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"react"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Prism`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"prismjs"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`PrismDiff`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"./PrismDiff"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`PrismExample`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`useLayoutEffect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Prism`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`highlightAll`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`pre`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`code className`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"language-javascript"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`var`}</span>{` str `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"text before diff"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`code`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`
`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PrismDiff`}</span>{`
        string1`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'const foo = "bar"'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        string2`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'const professionalVariable = "bar";'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        mode`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"words"`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`
`}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
      `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`code className`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"language-javascript"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` textAfterDiff`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`code`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
    `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span>{`pre`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`default`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PrismExample`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Hope this helps as you make your own content!`}</p>
    <p>{`There is also an official Prism js plugin that supports highlighting for line diffs, but I don't think it handles the string comparison for you.`}</p>
    <p><a parentName="p" {...{
        "href": "https://prismjs.com/plugins/diff-highlight/"
      }}>{`https://prismjs.com/plugins/diff-highlight/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      